
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmFile from '@/components/shared/TmFile.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: { TmFile, TmModal },
  setup() {
    const { openModal } = useModals()
    const files = [
      {
        extension: 'csv',
        label: 'Comma-separated values (.csv)',
      },
      {
        extension: 'xls',
        label: 'Microsoft Excel workbook (.xlsx)',
      },
    ]

    const openDownloadToEmailModal = () => {
      openModal('singleInput', {
        modalTitle: 'Send the download link to an email',
        description: 'Export of data can take some time. Please enter email address, and we will send a download link as soon as possible.',
        label: 'Email address',
        placeholder: 'Enter email address',
        btnText: 'Send',
      })
    }

    return { files, openDownloadToEmailModal }
  },
})
