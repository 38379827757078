import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0721edb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body-text-semi-bold-14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_file = _resolveComponent("tm-file")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "downloadModal",
    "modal-title": "Download list",
    "no-footer": "",
    "modal-classes": "download-modal"
  }, {
    "modal-content": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.extension,
          class: "file-item",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDownloadToEmailModal && _ctx.openDownloadToEmailModal(...args)))
        }, [
          _createVNode(_component_tm_file, {
            file: item.extension,
            size: "xxSmall",
            class: "mr-2"
          }, null, 8, ["file"]),
          _createElementVNode("span", _hoisted_1, _toDisplayString(item.label), 1)
        ]))
      }), 128))
    ]),
    _: 1
  }))
}